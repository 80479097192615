@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@font-face {
  font-family: 'Heland';
  src: url('./assets//Heland.ttf');
}
* {
  /* font-family: 'Indie Flower', cursive; */
  font-family: 'Heland';
}

* {
  margin: 0;
}

.App {
  background-image: url('./assets/images/5852.jpg');
  /* min-height: 130vh; */
  overflow: scroll;
  background-attachment: fixed;
  /* background-position: center; */
  background-size: 100%;
  
  background-repeat: no-repeat;
}

.app-image img {
  /* background-image: url('./assets/images/bg-logo.jpg'); */
  /* height: 80vh; */
  width: 100vw;
  object-fit: cover;
}

.story-group {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  align-items: center;
}

.sg-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.sg-title {
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
background-color: white;
margin-bottom: 5vh;
border-radius: 20px;
color: black;
width: 50%;
padding: 20px 0px;
box-shadow: 0 8px 24px rgba(27, 28, 28, 0.8);

}

.sg-title h2 {
  font-size: 60px;
}

.sg-title p {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

@media all and (min-width: 1281px) {

}

@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
@media all and (min-width: 768px) and (max-width: 1024px) {
  .audio-box {
    width: 40vw !important;
    flex-direction: column;
  }

  audio {
    width: 30vw !important;
  }

  .ab-left img {
    height: 25vh !important;
    width: 100% !important;
  }

  .ab-right {
    text-align: center;
  }
 }
 
@media all and (min-width: 480px) and (max-width: 768px) {
  .story-group {
    margin-top: 20px !important;
  }

  .App {
    background-position: center;
  }

  .audio-box {
    width: 75vw !important;
    flex-direction: column;
  }

  audio {
    width: 50vw !important;
  }

  .ab-left img {
    height: 25vh !important;
    width: 100% !important;
  }

  .ab-right {
    text-align: center;
  }
 }
 
@media all and (max-width: 480px) { 

  .story-group {
    margin-top: 20px !important;
  }

  .App {
    background-position: center;
  }

  .audio-box {
    width: 75vw !important;
    flex-direction: column;
  }

  audio {
    width: 50vw !important;
  }

  .ab-left img {
    height: 25vh !important;
    width: 100% !important;
  }

  .ab-right {
    text-align: center;
  }
}