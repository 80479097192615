.audio-box {
    background-color: #fffffff4;
    /* background-color: black; */
    width: 27vw;
    box-shadow: 0 8px 24px rgba(27, 28, 28, 0.8);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.ab-active {
    background-color: #b6d1d1;
}

.audio-box h1 {
    font-size: 34px;
    /* color: white; */
}

.ab-right {
    padding-left: 20px;
}

.ab-left {
    flex: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ab-left img {
    width: 5vw;
}

.audio-box audio {
    width: 20vw;
    margin-top: 10px;
}