.footer {
    height: 100px;
    background-color: white;
    bottom: 0;
    /* position: fixed; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer p {
    font-size: 20px;
}